.home {
  flex-grow: 1;
}

.home-content {
  flex-grow: 1;
  height: 100vh;
}

.home-container-base {
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.home-container {
  padding-top:16px;
  padding-bottom: 32px;
}

.home-container-mobile {
  padding-top: 80px;
  padding-bottom: 56px;
}

.home-toolbar {
  align-items: center;
  height: 64px;
  padding-right: 8px;
}

.home-title {
  align-self: 'flex-end';
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.334;
  margin: 0;
  overflow: hidden;
  padding-top: 16px;
  padding-bottom: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home-bottom-nav {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.home-paper {
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 16px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.home-paper-mobile {
  margin-bottom: 16px;
}

.home-language-select {
  color: #FFFFFF;
  margin-left: 8px;
  margin-right: 16px;
}

.home-language-select select {
  color: #FFFFFF;
}

.home-language-select::before {
  border-color: #FFFFFF !important;
}

.home-language-select svg {
  color: #FFFFFF;
}

.home-language-select option {
  color: #000000;
}

.home-grid {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
  width: calc(100% + 24px);
}

.home-grid-item {
  box-sizing: border-box;
  flex-basis: 100%;
  flex-grow: 0;
  margin: 0;
  max-width: 100%;
  padding: 12px;
}

.home-mobile-grid-item {
  box-sizing: border-box;
  padding: 12px;
  margin: 0;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}